body {
  margin: 0;
  font-family: "Inter" !important;
}

button {
  border-radius: 50px;
  border: none;
  padding: 0.6rem;
  transition: transform 450ms;
  text-decoration: none;
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  background-color: #040631;
  color: white;
  font-family: "Inter";
  margin-right: 3rem;
  margin-top: 2rem;
}

button:hover {
  transform: translateY(-2px);
}

h2 {
  font-weight: 400;
  font-size: 2.2rem;
  margin-bottom: 2.1rem;
}

.page-content {
  padding-bottom: 2rem;
  line-height: 1.5;
}

.icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.jkl {
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
}

.header-wrapper {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: headerdiv;
  z-index: 0;
}

.header {
  margin: 20px 20px 20px 20px;

}

.header-ingress {
  padding-top: 20px;
  font-size: 20px;
}

.logo {
  max-height: 100px;
}

.jkl-tablist {
  border-bottom: none !important;
}

.jkl-tabs {
  margin-top: 20px;
}

.jkl-tab {
  color: #040631 !important;
  padding: 10px 15px 10px 15px !important;
  margin-left: 20px;
}

.jkl-tab[aria-selected="true"] {
  font-weight: 400 !important;
}

.hamburger-wrapper {
  display: flex !important;
  flex-direction: column;
  align-items: end;
  position: absolute;
  right: 0;
  margin-right: 20px;
}

.jkl-hamburger:focus {
  box-shadow: none !important;
}

.flags {
  padding-top: 32px;
}

.flags img {
  padding-right: 5px;
}

.main {
  background-color: grey;
  width: 100%;
  height: 20px;
}

.menu-link {
  text-decoration: none;
}

.menu-link:hover {
  color: white !important;
  background-color: #040631;
  border-radius: 20px;
}

main {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 40px; */
}

.main-home {
  z-index: -1;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  /* top: 50px; */
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: mapdiv;
  justify-content: center;
  background: linear-gradient(315deg, rgba(255,255,255,0.486453956582633) 0%, rgba(230,208,51,0.42482930672268904) 0%, rgba(4,6,49,0.48365283613445376) 55%);
}

.content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px;
  margin-top: 30px;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: headerdiv;
}

.img-container {

  margin-right: 5px;
}

.img-3-container {
  width: 100%;
  display: flex;
  padding: 40px 25px 25px 25px;
  justify-content: space-between;
}

.img-3 {
  max-height: 500px;
}

.img {
  max-height: 500px;
  margin-left: 20px;
}

.content-container {
  width: 60%;
}

.area-content {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}

.reg-link {

}

.hamburger-menu-items {
  text-decoration: none;
  color: #040631;
}

.link-button {
  border-radius: 50px;
  font-weight: 400 !important;
  border: none;
  padding: 0.6rem;
  transition: transform 450ms;
  text-decoration: none;
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  background-color: #040631;
  color: white;
  font-family: "Inter";
  margin-right: 3rem;
  margin-top: 2rem;
}

.link-button:hover {
  background-color: #F3D9A7;
  color: #040631;
}

.jkl-nav-card {
  font-weight: 500 !important;
  margin: 25px;
  cursor: pointer;
  background-color: white !important;
  box-shadow: 0 0.25rem 0.9375rem rgba(37,42,49,0.1) !important;
}

.jkl-nav-card__description {
  font-weight: 400 !important;
}

.jkl-nav-card__content {
  margin: 1rem;
}

.jkl-nav-card__info {
  border-color: #040631 !important;
}

.jkl-nav-card__link::after {
  content: "" !important;
}

.nav-card-img-container {
  overflow: hidden;
  width: 300px;
  max-height: 200px;
}

.nav-card-img {
  width: 100%;
}

.select-wrapper {
  width: 100%;
}

.select-label {
  margin-left: 25px;
  margin-bottom: 5px;
}

.select {
  font-family: "Inter";
  padding: 10px 20px 10px 10px;
  border: 1px solid #040631;
  border-radius: 20px;
  margin-left: 25px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  background-image: url("./images/arrow.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 15px;
}

.select:focus {
  outline: none;
  border: 2px solid #040631;
}

.search-box {
  padding: 5px;
  background-color: white;
}

.search-box input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
}

.search-box input:focus {
  outline: none;
  border: 1px solid #040631;
}

.jkl-skeleton-element {
  background-color: #f2f2f2 !important;
}

.area-description {
  margin: 25px;
  line-height: 1.5;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.modal-content {
  background-color: white;
  border-radius: 0.25rem;
  padding: 1rem;
}

.modal-header,
.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  display: flex;
  margin: 5px;
  font-weight: 500;
  font-size: 20px;
}

.id {
  margin: 0;
  padding-left: 10px;
  font-weight: 400;
  color: #999999;
}

.modal-body {
  margin: 1rem;
  max-width: 700px;
  max-height: 700px;
}

.expand-button {
  font-weight: 700;
  cursor: pointer;
}

.description {
  line-height: 1.5rem;
  margin-bottom: 20px;
  max-height: 100px;
  overflow: scroll;
  margin-top: 20px;
  padding: 8px;
  border: 1px solid #040631;
  border-radius: 0.25rem;
}

.sections {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.modal-section {
  /* background-color: #f3d98c;
  padding: 5px 7px;
  border-radius: 0.25rem; */
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.modal-section svg {
height: 34px;
width: 34px;
}

.euro-icon {
  height: 20px !important;
}

.modal-section p {
  background-color: #f3d98c;
  padding: 5px 7px;
  border-radius: 0.25rem;
  min-width: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  height: 400px;
}

.carousel-item > img {
  width: 100%;
}

.indicators {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.indicators > button {
  margin: 2px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: transparent;
  color: #040631;
  font-weight: 400;
}

.indicators > button.active {
  font-weight: 700;
}

.ocean {
  position: absolute;
  top: 60%;
  left: 75%;
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 400;
}

.map {

}

.map-img {
  width: 100%;
  height: 110%;
  position: fixed;
  left: 0;
  top: -1px;
  animation-duration: 5s;
  animation-timing-function: ease;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: mapimg;
  background-image: linear-gradient(315deg, rgba(255,255,255,0.486453956582633) 0%, rgba(230,208,51,0.42482930672268904) 0%, rgba(4,6,49,0.48365283613445376) 55%);;
  background-size: 600% 100%;
}

.pins {
  z-index: 2;
  position: fixed;
}
.pin {
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50% 50% 50% 0;
  background: #040631;
  position: absolute;
  transform: rotate(-45deg);
  left: 49.5%;
  top: 91.5%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  position: fixed;
}

.mojacar {
  left: 53%;
  top: 77.5%;
  position: fixed;
}

.garrucha {
  left: 54.5%;
  top: 72.5%;
  position: fixed;
}

.vera {
  left: 45.5%;
  top: 65.5%;

  position: fixed;
}

.cabrera {
  left: 67%;
  top: 81%;
  position: fixed;
}

.losgallardos {
  left: 63.5%;
  top: 93%;
  position: fixed;
}

.antas {
  left: 57%;
  top: 96%;
  position: fixed;
}

.calapanizo {
  left: 54.5%;
  top: 55.5%;
  position: fixed;
}

.aguilas {
  left: 65%;
  top: 31.5%;
  position: fixed;
}

.pulpi {
  left: 50.5%;
  top: 32.5%;
  position: fixed;
}

.sanjuan {
  left: 57.5%;
  top: 49.5%;
  position: fixed;
}

.pin-name {
  z-index: 1;
  font-weight: 500;
  font-size: 0.8rem;
  position: absolute;
  left: 47.5%;
  top: 94%;
  margin: -20px 0 0 -20px;
  animation-name: bouncetext;
  padding: 0.2rem;
  animation-duration: 1s;
  border-radius: 5px;
  color: inherit;
  text-decoration: none;
  position: fixed;
}

.pin-name:visited {
  color: inherit;
}

.mojacarname {
  left: 51%;
  top: 80%;
  position: fixed;
}

.garruchaname {
  left: 52%;
  top: 75%;
  position: fixed;
}

.veraname {
  left: 45%;
  top: 68%;
  position: fixed;
}

.cabreraname {
  left: 65.8%;
  top: 84%;
  position: fixed;
}

.losgallardosname {
  left: 61%;
  top: 96%;
  position: fixed;
}

.aljarizname {
  left: 56.3%;
  top: 99%;
  position: fixed;
}

.calapanizoname {
  left: 52.5%;
  top: 58%;
  position: fixed;
}

.aguilasname {
  left: 64%;
  top: 34%;
  position: fixed;
}

.pulpiname {
  left: 50%;
  top: 35%;
  position: fixed;
}

.sanjuanname {
  left: 53%;
  top: 52%;
  position: fixed;
}

.footer {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #040631;
  color: white;
  z-index: 1;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom:20px;
  width: 100%;
  max-width: 1200px;
}

.footer-element-content {
  padding-top: 16px;
  font-weight: 300;
}

.menu-link-footer {

    color: white !important;
    display: block;
}

@-webkit-keyframes headerdiv {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes headerdiv {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes mapdiv {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mapdiv {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes mapimg {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes mapimg {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 50% 0%;
  }
}

@keyframes bouncetext {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

@media (max-width: 700px) {
  .map {
    display: none;
  }
  .map-img {
    display: none;
  }
  .pin-name {
    display: none;
  }
  .pin {
    display: none;
  }

  .logo {
    max-height: 70px;
  }
}

@media (max-width: 750px) {
  .jkl-tablist {
    display: none !important;
  }
  .img-container {
    display: none;
  }
  .content-container {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .jkl-nav-card {
    width: 100%;
  }

  .nav-card-img-container {
    width: 100%;
    max-height: none;
  }

  .map {
    display: none;
  }

  .main-home {
    background: white;
  }

  .ocean {
    display: none;
  }
}

@media (min-width: 751px) {
  .hamburger-wrapper {
    display: none !important;
  }
}
